@font-face {
  font-family: gotham;
  src: url(../../assets/fonts/GothamPro.eot);
}

@font-face {
  font-family: gotham;
  font-weight: 500;
  src: url(../../assets/fonts/GothamPro-Medium.eot);
}
b {
  font-weight: 500;
}
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: gotham;
  height: 100%;
  /* fallback for old browsers */
  overflow: hidden;
  font-family: gotham;
}

*,
*:before,
*:after {
  font-family: gotham;
  box-sizing: inherit;
}

body {
  user-select: none;
  touch-action: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: #3e4a54;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: gotham;
}

@media screen and (orientation:landscape) {
  .App {
    visibility: hidden;
  }

  .rotator {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    display: block;
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    background-position: center center;
    background-image: url('../../assets/images/rotate.gif');
    background-size: 50%;
  }
}

@media screen and (orientation:portrait) {
  .App {
    visibility: visible;
  }
}

.splash-link {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.App {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.2vmin);
  padding: .5em;
  line-height: 1.1;
}
.rules-of-game {
  height: 100% !important;
  width: 100% !important;
  display: block;
  position: absolute;
  background: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.navigation {
  position: absolute;
  bottom: 0;
  z-index: 10 !important;
  width: 100%;
}
.the-rules {
  text-align: center;
  color: #071d49;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.95);
  border-radius: 8px;
  padding: .5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  width: 90%;
  animation-name: showUp;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

.answer {
  flex: 1;
  margin: .5em;
  font-size: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: center;
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'gotham';
  text-align: center;
  color: #071d49;
}

.answer::after {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #FFF;
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.green::after {
  background-color: green;
}
.red::after {
  background-color: red;
  opacity: 0.7;
}
.green, .red {
  color: #fff;
}
.question-row {
  margin-top: 2rem;
  display: block;
}
.finish::after {
  /* animation-name: moveLeft;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  background-repeat: repeat-x; */
}
.finish::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/window-blind.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  animation-name: moveDown;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.start::before {
  animation-name: moveUp;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/window-blind.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

.answer-row {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  flex-direction: column;
}

.survey-holder {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
}

.survey {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    animation-name: showUp;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

.splash-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.be-inspired {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30%;
  width: 100%;
  background: url("../../assets/images/be-inspired.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  animation-name: showUp;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}
.end-points {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.2rem;
  text-align: center;
  display: block;
  width: 80%;
  color: rgb(43, 58, 136);
}
.question-hero {
  text-align: center;
  font-size: 1.8em;
  background: #b4b4b4;
  background: -webkit-gradient(linear, left top, right top, from(#b4b4b4), color-stop(50%, white), color-stop(50%, white), to(#b4b4b4));
  background: -o-linear-gradient(left, #b4b4b4 0%, white 50%, white 50%, #b4b4b4 100%);
  background: linear-gradient(to right, #b4b4b4 0%, white 50%, white 50%, #b4b4b4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cccccc', endColorstr='#cccccc',GradientType=1 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  z-index: 9;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}

@keyframes moveUp {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}
@keyframes moveLeft {
  from {
    background-position: 0 0;
    }

  to {
    background-position: 900% 0; 
  }
}

@keyframes endPoints {
  from {
    background: #3e4a54;
    color: #3e4a54;
  }
   
  to {
    background: #EFF0F0;
    color: #071d49;
  }
}

@keyframes showUp {
  from {
    opacity: 0;
  }
   
  to {
    opacity: 1;
  }
}



.osvoivte-poeni {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #EFF0F0;
  animation-name: endPoints;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

.top {
  top: 20%;
}

.bottom {
  bottom: 23%;
}

.App>* {
  z-index: 1;
}

.row {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.question-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/windows-repeat.png");
  background-repeat: repeat-x;
  background-size: cover;
  background-position: 50% 0%;
}

.question-row {
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  width: 100%;
  height: 80%;
  z-index: -2;
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: -2 !important;
}

#tokyo {
  background-image: url("../../assets/cities/tokyo.jpg");
}
#ny {
  background-image: url("../../assets/cities/newyork.jpg");
}
#dubai {
  background-image: url("../../assets/cities/dubai.jpg");
}
#istanbul {
  background-image: url("../../assets/cities/istanbul.jpg");
}
#hk {
  background-image: url("../../assets/cities/hongkong.jpg");
}
#paris {
  background-image: url("../../assets/cities/paris.jpg");
}
#seul {
  background-image: url("../../assets/cities/seul.jpg");
}
#monako {
  background-image: url("../../assets/cities/monako.jpg");
}
#berlin {
  background-image: url("../../assets/cities/berlin.jpg"); 
}



#tokyo2 {
  background-image: url("../../assets/cities/tokyo2.jpg");
}
#ny2 {
  background-image: url("../../assets/cities/newyork2.jpg");
}
#dubai2 {
  background-image: url("../../assets/cities/dubai2.jpg");
}
#istanbul2 {
  background-image: url("../../assets/cities/istanbul2.jpg");
}
#hk2 {
  background-image: url("../../assets/cities/hongkong2.jpg");
}
#paris2 {
  background-image: url("../../assets/cities/paris2.jpg");
}
#seul2 {
  background-image: url("../../assets/cities/seul2.jpg");
}
#monako2 {
  background-image: url("../../assets/cities/monako2.jpg");
}
#berlin2 {
  background-image: url("../../assets/cities/berlin2.jpg"); 
}



#tokyo3 {
  background-image: url("../../assets/cities/tokyo3.jpg");
}
#ny3 {
  background-image: url("../../assets/cities/newyork3.jpg");
}
#dubai3 {
  background-image: url("../../assets/cities/dubai3.jpg");
}
#istanbul3 {
  background-image: url("../../assets/cities/istanbul3.jpg");
}
#hk3 {
  background-image: url("../../assets/cities/hongkong3.jpg");
}
#paris3 {
  background-image: url("../../assets/cities/paris3.jpg");
}
#seul3 {
  background-image: url("../../assets/cities/seul3.jpg");
}
#monako3 {
  background-image: url("../../assets/cities/monako3.jpg");
}
#berlin3 {
  background-image: url("../../assets/cities/berlin3.jpg"); 
}